import React from "react";
import { Container, Row, Col, Button, FormGroup, Form } from "reactstrap";
import "../assets/css/custom.css";

import { postApi } from "../services/axiosInstance";
import { API_PATH } from "../services/apipath";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import logo from "../assets/img/brand/global_wings.png";

const Auth = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await postApi(API_PATH.ADMIN.LOGIN, data);
      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        navigate("/admin/index");
      }
    } catch (error) {
      // console.log(error, "login error");
    }
  };

  return (
    <>
      <div className="full-width-background ">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" lg="4">
              <div className="centered-content ">
                <div className="mainlogo">
                  <img src={logo} alt="Logo" className="w-50" />
                  {/* <h1 className="display-2">Global Wings</h1> */}
                </div>
                <h1 className="text-center">Welcomes You!</h1>
                <p className="text-center title">Let’s Partner Up and Achieve Success Together!
                </p>

                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="mb-3">
                    <label htmlFor="email" className="mb-1 text-dark">
                      Email
                    </label>
                    <input
                      className="w-100 addstudent inputfluid"
                      placeholder="Enter Your Email"
                      type="email"
                      autoComplete="new-email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && <span>This field is required</span>}
                  </FormGroup>

                  <FormGroup className="mt-4">
                    <label htmlFor="password" className="mb-1 text-dark">
                      Password
                    </label>
                    <input
                      className="w-100 addstudent inputfluid"
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      {...register("password", { required: true })}
                    />
                    {errors.password && <span>This field is required</span>}
                  </FormGroup>

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Login
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>

            <Col md="6" lg="4">

            </Col>

            <Col md="6" lg="4">
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
