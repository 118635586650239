import { Link, useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import "../../assets/css/custom.css";

const AdminNavbar = (props) => {

  const institute = JSON.parse(localStorage.getItem('institute')) 
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    navigate('/auth/login');
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h3 className="text-dark text-uppercase ml-lg-0 ml-3">Institute Panel</h3>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm">
                    <img
                      alt="Institute Avatar"
                      src={`${process.env.REACT_APP_BASE_URL}${props.data.image}`}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold text-dark">
                     {props.data.instituteName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt" style={{ marginRight: '8px' }} />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
              
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
