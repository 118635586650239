import { Card, Container, Row, Col } from "reactstrap";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header";
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { useEffect, useState } from "react";
import moment from "moment";
import { ChevronRight, Check, BookOpen, Laptop, Globe } from "lucide-react";
import "./subscription.css";
import { toast } from "react-toastify";

const Profile = () => {
  const [userData, setUserData] = useState([]);
  const getUser = async () => {
    try {
      const res = await getApi(API_PATH.ADMIN.GET_ADMINSTUDENT);
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  const [selectedDuration, setSelectedDuration] = useState("30");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);

  const currency = "INR";
  const receiptId = `${userData._id}_${new Date().getTime()}`;
  const planTypes = {
    30: {
      // Core: {
      //   name: "Core Plan",
      //   price: 2499,
      //   icon: <Laptop className="plan-icon text-primary" />,
      //   features: [
      //     "Basic Learning Resources",
      //     "Standard Video Lectures",
      //     "Practice Quizzes",
      //     "Community Support",
      //   ],
      // },
      // Academic: {
      //   name: "Academic Pro",
      //   price: 2499,
      //   icon: <BookOpen className="plan-icon text-success" />,
      //   features: [
      //     "Comprehensive Curriculum",
      //     "Advanced Research Materials",
      //     "Personalized Study Paths",
      //     "Expert Tutoring Sessions",
      //     "Academic Progress Tracking",
      //   ],
      // },
      Both: {
        name: "Ultimate Learning Bundle",
        price: 2499,
        icon: <Globe className="plan-icon text-purple" />,
        features: [
          "All Core Plan Features",
          "Complete Academic Resources",
          "Unlimited Access to Courses",
          "Personalized Mentorship",
          "Certificate of Completion",
          "24/7 Premium Support",
        ],
      },
    },
    60: {
      // Core: {
      //   name: "Core Plan",
      //   price: 5999,
      //   icon: <Laptop className="plan-icon text-primary" />,
      //   features: [
      //     "Extended Learning Resources",
      //     "Expanded Video Lectures",
      //     "Comprehensive Practice Quizzes",
      //     "Enhanced Community Support",
      //     "Bi-Monthly Content Updates",
      //   ],
      // },
      // Academic: {
      //   name: "Academic Pro",
      //   price: 5999,
      //   icon: <BookOpen className="plan-icon text-success" />,
      //   features: [
      //     "Advanced Curriculum Access",
      //     "In-Depth Research Materials",
      //     "Customized Learning Tracks",
      //     "Weekly Tutoring Sessions",
      //     "Detailed Academic Analytics",
      //     "Research Paper Guidance",
      //   ],
      // },
      Both: {
        name: "Ultimate Learning Bundle",
        price: 5999,
        icon: <Globe className="plan-icon text-purple" />,
        features: [
          "Comprehensive Core Features",
          "Full Academic Resources",
          "Unlimited Course Access",
          "Dedicated Personal Mentor",
          "Advanced Learning Paths",
          "Priority Support",
          "Quarterly Performance Review",
        ],
      },
    },
    90: {
      // Core: {
      //   name: "Core Plan",
      //   price: 11999,
      //   icon: <Laptop className="plan-icon text-primary" />,
      //   features: [
      //     "Full Learning Ecosystem",
      //     "Comprehensive Video Library",
      //     "Advanced Practice Modules",
      //     "Community Expert Interaction",
      //     "Monthly Content Refreshes",
      //     "Skill Assessment Tools",
      //   ],
      // },
      // Academic: {
      //   name: "Academic Pro",
      //   price: 11999,
      //   icon: <BookOpen className="plan-icon text-success" />,
      //   features: [
      //     "Comprehensive Academic Suite",
      //     "Research-Grade Materials",
      //     "Advanced Learning Paths",
      //     "Bi-Weekly Expert Consultations",
      //     "Comprehensive Academic Tracking",
      //     "Publication Support",
      //     "Specialized Workshop Access",
      //   ],
      // },
      Both: {
        name: "Ultimate Learning Bundle",
        price: 11999,
        icon: <Globe className="plan-icon text-purple" />,
        features: [
          "Complete Learning Ecosystem",
          "Unlimited Academic Resources",
          "Personalized Mastery Program",
          "Dedicated Academic Mentor",
          "Priority Access to New Courses",
          "Comprehensive Skill Development",
          "Professional Certification Preparation",
        ],
      },
    },
    365: {
      // Core: {
      //   name: "Core Plan",
      //   price: 23999,
      //   icon: <Laptop className="plan-icon text-primary" />,
      //   features: [
      //     "Full Learning Ecosystem",
      //     "Comprehensive Video Library",
      //     "Advanced Practice Modules",
      //     "Community Expert Interaction",
      //     "Monthly Content Refreshes",
      //     "Skill Assessment Tools",
      //   ],
      // },
      // Academic: {
      //   name: "Academic Pro",
      //   price: 23999,
      //   icon: <BookOpen className="plan-icon text-success" />,
      //   features: [
      //     "Comprehensive Academic Suite",
      //     "Research-Grade Materials",
      //     "Advanced Learning Paths",
      //     "Bi-Weekly Expert Consultations",
      //     "Comprehensive Academic Tracking",
      //     "Publication Support",
      //     "Specialized Workshop Access",
      //   ],
      // },
      Both: {
        name: "Ultimate Learning Bundle",
        price: 23999,
        icon: <Globe className="plan-icon text-purple" />,
        features: [
          "Complete Learning Ecosystem",
          "Unlimited Academic Resources",
          "Personalized Mastery Program",
          "Dedicated Academic Mentor",
          "Priority Access to New Courses",
          "Comprehensive Skill Development",
          "Professional Certification Preparation",
        ],
      },
    },
  };

  const planCategories = Object.keys(planTypes["30"]);

  const paymentHandler = async () => {
    if (userData.canPurchase !== 1) {
      toast.warn(
        `Plan not expired yet. Please wait till ${moment(
          userData.planExpireIn
        ).format("MMMM DD YYYY")}`
      );
      return;
    }

    const amount = selectedAmount * 100;
    const body = {
      amount,
      currency,
      receipt: receiptId,
    };
    const response = await postApi(API_PATH.ADMIN.CREATE_PAYMENT, body);
    const order = await response.data; 

    var options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: userData.instituteName, //your business name
      description: `${
        userData.instituteName
      } purchasing ${selectedPlan} on ${moment(new Date()).format('MMMM DD yyyy')}`,
      image: userData.image,
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
          days: selectedDuration,
          planType:
            selectedPlan === "Core" ? 1 : selectedPlan === "Academic" ? 2 : 0,
        }; 
        const validateRes = await postApi(API_PATH.ADMIN.VERIFY_PAYMENT, body);
        const jsonRes = await validateRes.data;
        if (validateRes.data) {
          getUser();
        } 
      },
      prefill: {
        name: userData.instituteName, //your customer's name
        email: userData.email,
        contact: userData.phoneNumber, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: userData.location,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="bg-secondary shadow py-lg-4 py-4">
          <Col xl="8" className="order-sm-2 planon">
            <h3 className="px-3">Self-Managed Admin Panel : </h3>
            <ol>
              <li>
                {" "}
                Flexible Plan Options: Choose from{" "}
                <span className="planbold">
                  Academic, Core, or Both
                </span> Plans{" "}
              </li>
              <li>
                {" "}
                Unlimited Student Management : Can add an{" "}
                <span className="planbold">Unlimited Number</span> of students
              </li>
              <li> Customizable Student Subscriptions</li>
              <li>
                {" "}
                Control Over Student Profiles : Can edit student details,
                including{" "}
                <span className="planbold">
                  Names, Usernames, and Passwords
                </span>
                .
              </li>
              <li>
                {" "}
                One-Click Student Removal : Can remove all students in a single
                click
              </li>
              <li> Access to Create & Control Student Logins</li>
            </ol>
          </Col>

          <Col xl="4" className="d-flex justify-content-center order-sm-1 plan">
            <div className="">
              <h3 className="">Plan Expiry Date :</h3>
              <button disabled className="open-modal-btn btndisable w-100">
                {moment(userData.planExpireIn).format("MMMM DD YYYY")}
              </button>
            </div>
          </Col>
        </Row>

        <Row className="">
          <div className="container-fluid subscription-plans py-5 bg-secondary">
            <div className="container ">
              {/* Duration Selector */}
              <div className="duration-selector mb-5">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Duration Selector"
                >
                  {["30", "60", "90", "365"].map((duration) => (
                    <button
                      key={duration}
                      onClick={() => setSelectedDuration(duration)}
                      className={`btn ${
                        selectedDuration === duration
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                    >
                      {duration} Days
                    </button>
                  ))}
                </div>
              </div>

              {/* Plans Container */}
              <div className="row g-4">
                {planCategories.map((category) => {
                  const plan = planTypes[selectedDuration][category];
                  return (
                    <div key={category} className="col-md-4">
                      <div
                        className={`card plan-card h-100 ${
                          category === "Both" ? "featured-plan" : ""
                        }`}
                      >
                        <div className="card-body">
                          {/* Plan Header */}
                          <div className="plan-header mb-3">
                            {plan.icon}
                            <div className="plan-title">
                              <h4 className="card-title mb-0">{plan.name}</h4>
                              {category === "Both" && (
                                <span className="badge bg-primary">
                                  Best Value
                                </span>
                              )}
                            </div>
                          </div>

                          {/* Pricing */}
                          <div className="plan-pricing mb-4">
                            <h2 className="price">&#8377; {plan.price}</h2>
                            <p className="duration">
                              per {selectedDuration} days
                            </p>
                          </div>

                          {/* Features */}
                          <ul className="plan-features list-unstyled">
                            {plan.features.map((feature, index) => (
                              <li key={index} className="feature-item">
                                <Check className="feature-icon" />
                                <span>{feature}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* CTA Button */}
                        <div className="card-footer">
                          <button
                            onClick={(e) => {
                              setSelectedPlan(category);
                              setSelectedAmount(plan.price);
                              paymentHandler(e.preventDefault());
                            }}
                            className={`btn w-100 plan-btn ${
                              category === "Core"
                                ? "btn-primary"
                                : category === "Academic"
                                ? "btn-success"
                                : "btn-purple"
                            }`}
                          >
                            Choose {category} Plan
                            <ChevronRight className="btn-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
